import React from "react";

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"

const Terms = () => {
  return (
    <Layout>
      <SEO title="Dassport" />
      <div className="terms-wrapper">
        <h2 className="header">Algemene voorwaarden Kaigo IT Solutions</h2>
        <div className="wrapper">
          <div>
            <p>Deze algemene voorwaarden zijn van toepassing op alle aanbiedingen en overeenkomsten die daaruit voortvloeien tussen Kaigo IT Solutions te Rotterdam, Kamer van Koophandel 73544698 en haar wederpartijen ("Opdrachtgever").
            Bepalingen of voorwaarden gesteld door Opdrachtgever die afwijken van, of niet voorkomen in, deze algemene voorwaarden zijn voor Kaigo IT Solutions alleen bindend indien en voor zover dat uitdrukkelijk schriftelijk is aanvaard.</p>

            <h4 className="header">1. Offerte en aanvaarding</h4>
            <p>1.1. Kaigo IT Solutions stelt een offerte op waarin Kaigo IT Solutions aangeeft welke werkzaamheden ("de Diensten") Kaigo IT Solutions aanbiedt te verrichten, wat bij de Diensten inbegrepen is en welk bedrag daarvoor verschuldigd zal zijn. Uitsluitend de in de offerte aangegeven omschrijving van de Diensten is bindend.</p>
            <p>1.2. In het algemeen omvatten de Diensten onderhoud van hardware en software, configuratie en installatie van software, maken van maatwerksoftware, leveren van maatwerksoftware, ondersteuning op afstand, en al hetgeen daarmee samenhangt. Andere werkzaamheden worden alleen verricht indien dit in de offerte vermeld is.</p>
            <p>1.3. Een offerte is geheel vrijblijvend en geldig 14 dagen na verzending, tenzij anders aangegeven in de offerte. Kaigo IT Solutions kan nimmer verplicht worden een aanvaarding na deze periode aan te nemen, maar indien Kaigo IT Solutions daartoe overgaat, is de offerte alsnog aanvaard.</p>
            <p>1.4. De overeenkomst komt tot stand op het moment waarop de mededeling inhoudende aanvaarding van de offerte door Opdrachtgever wordt ontvangen door Kaigo IT Solutions. Deze mededeling kan per e-mail worden gedaan.</p>
            <p>1.5. Indien Opdrachtgever niet expliciet aangeeft akkoord te gaan met de offerte, maar er desondanks mee instemt, of die indruk wekt, dat Kaigo IT Solutions werkzaamheden verricht die binnen de omschrijving van de Diensten vallen, dan wordt de offerte als aanvaard beschouwd. Dit geldt ook wanneer Opdrachtgever Kaigo IT Solutions verzoekt bepaalde werkzaamheden te verrichten zonder een formele offerte af te wachten.</p>
            <p>1.6. Het wijzigen van de Diensten is alleen mogelijk met instemming van beide partijen, behoudens voor zover elders in deze voorwaarden anders is bepaald.</p>
            <p>1.7. Kaigo IT Solutions zal bij verzoeken om meerwerk een passende offerte uitbrengen.</p>

            <h4 className="header">2. Oplevering en aanvaarding</h4>
            <p>2.1. Kaigo IT Solutions zal na uitvoering van werkzaamheden of gedeelten daarvan het resultaat opleveren wanneer dit in haar professionele opinie voldoet aan de specificaties of geschikt is voor gebruik.</p>
            <p>2.2. Opdrachtgever dient vervolgens binnen vijf werkdagen na oplevering het opgeleverde te evalueren en goed of af te keuren. Indien Opdrachtgever niet binnen deze periode het opgeleverde afkeurt, wordt het opgeleverde geacht te zijn aanvaard.</p>
            <p>2.3. Indien werk in fasen wordt opgeleverd, dient Opdrachtgever na oplevering van elke fase de goed- of afkeuring van het deel van het werk van die fase te geven op de wijze zoals in het vorige lid bepaald. Opdrachtgever mag een goed- of afkeuring in een latere fase niet baseren op aspecten die in een eerdere fase goedgekeurd zijn.</p>
            <p>2.4. Indien Opdrachtgever het opgeleverde geheel of gedeeltelijk afkeurt, zal Kaigo IT Solutions zich inspannen de reden van afkeuring zo snel mogelijk weg te nemen. Dit kan Kaigo IT Solutions doen door het resultaat te reviseren of gemotiveerd aan te geven waarom de reden niet opgaat. Opdrachtgever heeft vervolgens wederom vijf werkdagen om de revisie of motivatie goed of af te keuren.</p>
            <p>2.5. Indien Opdrachtgever na de eerste revisie of motivatie het opgeleverde geheel of gedeeltelijk heeft afgekeurd zullen er revisierondes volgen totdat het werk naar het redelijke oordeel van Opdrachtgever is afgerond.</p>
            <p>2.6. Indien een partij aangeeft verdere revisies niet (meer) zinvol te achten, worden beide partijen gerechtigd de overeenkomst op te zeggen voor de betreffende Dienst. In dat geval zal Opdrachtgever de daadwerkelijk door Kaigo IT Solutions gemaakte uren vergoeden, met als maximum het voor het afgekeurde geoffreerde bedrag. Opdrachtgever wordt daarmee echter niet gerechtigd het afgekeurde te gebruiken op welke wijze dan ook.</p>
            <p>2.7. Na aanvaarding van het opgeleverde vervalt iedere aansprakelijkheid voor gebreken in het opgeleverde, tenzij Kaigo IT Solutions het gebrek kende of had moeten kennen ten tijde van aanvaarding. In ieder geval vervalt iedere aansprakelijkheid voor gebreken na verloop van een jaar na beëindiging van de Overeenkomst om welke reden dan ook.</p>

            <h4 className="header">3. Levering van de Diensten</h4>
            <p>3.1. Nadat de overeenkomst tot stand is gekomen zullen de Diensten zo spoedig mogelijk door Kaigo IT Solutions uitgevoerd worden conform de offerte, daarbij rekening houdend met redelijke wensen van Opdrachtgever.</p>
            <p>3.2. Opdrachtgever is gehouden al datgene te doen en laten wat redelijkerwijs wenselijk en nodig is om een juiste en tijdige uitvoering van de Diensten mogelijk te maken. In het bijzonder draagt Opdrachtgever er zorg voor dat alle gegevens, waarvan Kaigo IT Solutions aangeeft dat deze noodzakelijk zijn of waarvan de Opdrachtgever redelijkerwijs behoort te begrijpen dat deze noodzakelijk zijn voor het uitvoeren van de Diensten, tijdig aan Kaigo IT Solutions worden verstrekt.</p>
            <p>3.3. Opdrachtgever zal Kaigo IT Solutions toegang geven tot alle plaatsen, diensten en accounts onder haar beheer (zoals webhostingaccounts) die Kaigo IT Solutions redelijkerwijs nodig heeft om de Diensten te leveren.</p>
            <p>3.4. Kaigo IT Solutions garandeert dat de Diensten zorgvuldig, degelijk en zo goed mogelijk worden uitgevoerd. Indien een goede uitvoering van de Diensten dit vereist, heeft Kaigo IT Solutions het recht bepaalde werkzaamheden te laten verrichten door derden. Kaigo IT Solutions is en blijft naar Opdrachtgever toe de verantwoordelijke.</p>
            <p>3.5. Kaigo IT Solutions is gerechtigd, maar nimmer verplicht, de juistheid, volledigheid of samenhang van de aan hem ter beschikking gestelde bronmaterialen, eisen of specificaties te onderzoeken en bij constatering van eventuele onvolkomenheden de overeengekomen werkzaamheden op te schorten tot het moment dat Opdrachtgever de betreffende onvolkomenheden heeft weggenomen.</p>
            <p>3.6. Tenzij anders overeengekomen is Kaigo IT Solutions geen partij bij de levering van diensten van derden, zoals softwarelicenties of hosting die nodig zijn bij Diensten, ook niet indien Kaigo IT Solutions deze diensten afneemt ten behoeve van Opdrachtgever. Bij als Dienst geleverde softwarelicenties is het afhankelijk van de leverancier of Kaigo IT Solutions de contractuele wederpartij van Opdrachtgever is of de leverancier. Kaigo IT Solutions zal hierover adequaat informeren.</p>
            <p>3.7. Kaigo IT Solutions heeft het recht de Diensten (tijdelijk) niet of beperkt te leveren als Opdrachtgever ter zake van de overeenkomst een verplichting tegenover Kaigo IT Solutions niet na komt dan wel in strijd handelt met deze algemene voorwaarden.</p>
            <p>3.8. Kaigo IT Solutions zal zich inspannen bij een verzoek van Opdrachtgever tijdens kantooruren binnen 2 uur te reageren, tenzij anders afgesproken in de offerte.</p>

            <h4 className="header">4. Bepalingen over onderhoud</h4>
            <p>4.1. Onder onderhoud wordt verstaan het laten functioneren van bestaande hardware en/of software conform de offerte of nadere afspraak, en meer algemeen het herstellen van fouten.</p>
            <p>4.2. Kaigo IT Solutions zal zich inspannen het onderhoud zo goed mogelijk uit te voeren, maar is daarbij vaak afhankelijk van haar leverancier(s) en derden voor updates, foutherstelsoftware ('patches') of reserve-onderdelen. Kaigo IT Solutions is gerechtigd bepaalde updates of patches niet te installeren als dit naar haar oordeel een correcte werking van de software niet ten goede komt of niet in het belang is van Opdrachtgever.</p>
            <p>4.3. Als onderdeel van het onderhoud zal Kaigo IT Solutions zich inspannen om fouten in de Werken, zoals websites, databestanden, software, documentatie, adviezen, rapporten, analyses, ontwerpen, teksten, foto's, films, geluidsopnamen, afbeeldingen, audiovisueel materiaal, logo's of huisstijlen (hierna: "Werken") en bijbehorende software te herstellen. Kaigo IT Solutions is hierbij echter afhankelijk van leverancier(s) en derden. Bij nieuwe functionaliteit of wijzigingen die het functioneren van de software wezenlijk kunnen veranderen zal Kaigo IT Solutions hier vooraf overleg over voeren met Opdrachtgever.</p>
            <p>4.4. Kaigo IT Solutions zal zich inspannen om door Opdrachtgever verzochte veranderingen toe te voegen aan de software. Kaigo IT Solutions is steeds gerechtigd een verzoek te weigeren als deze naar haar oordeel niet haalbaar is of een goede werking of beschikbaarheid van de software kan belemmeren.</p>
            <p>4.5. Indien naar het oordeel van Kaigo IT Solutions een verzochte verandering het functioneren of de veiligheid van de software negatief kan beïnvloeden, zal Kaigo IT Solutions dit schriftelijk melden aan Opdrachtgever. Indien Opdrachtgever desondanks staat op de verandering en Kaigo IT Solutions deze doorvoert, geschiedt zulks op eigen risico van Opdrachtgever en zonder enige aansprakelijkheid voor Kaigo IT Solutions.</p>
            <p>4.6. Indien Opdrachtgever zelfstandig een wijziging aan door Kaigo IT Solutions geleverde resultaten wenst door te voeren, geschiedt dit geheel op eigen risico en verantwoordelijkheid van Opdrachtgever, tenzij Opdrachtgever de gewenste wijziging vooraf aan Kaigo IT Solutions heeft gemeld en Kaigo IT Solutions deze schriftelijk heeft goedgekeurd. Kaigo IT Solutions kan aan deze goedkeuring voorwaarden verbinden.</p>

            <h4 className="header">5. Bepalingen over remote support</h4>
            <p>5.1. Ondersteuning op afstand wordt geleverd per telefoon, e-mail en andere gezamenlijk af te spreken kanalen.</p>
            <p>5.2. Kaigo IT Solutions zal op verzoek van Opdrachtgever software voorstellen waarmee te ondersteunen computers op afstand kunnen worden benaderd. Het is de verantwoordelijkheid van Opdrachtgever om te zorgen dat zijn netwerk- en beveiligingsomgeving toelaat dat deze software werkt.</p>
            <p>5.3. Indien blijkt dat ondersteuning op afstand niet tot een bevredigende oplossing leidt of niet haalbaar is gezien de aard van het probleem, dan zal Kaigo IT Solutions in overleg treden met Opdrachtgever voor het zoeken naar een oplossing op locatie.</p>

            <h4 className="header">6. Ontwikkeling van werken</h4>
            <p>6.1. Indien een Dienst strekt tot het ontwikkelen, configureren en/of aanpassen van Werken, heeft Kaigo IT Solutions, tenzij anders overeen gekomen, het recht gebruik te maken van afbeeldingen, software en componenten van derden bij de ontwikkeling, configuratie of aanpassing van Werken.</p>
            <p>6.2. Het is Kaigo IT Solutions toegestaan gebruik te maken van open source software waarvan de rechten bij derden liggen. Dit betekent onder meer dat Kaigo IT Solutions open source software mag leveren aan Opdrachtgever en open source software mag verwerken in Werken die Kaigo IT Solutions maakt of aanpast in het kader van een Dienst. Indien de licentie van bepaalde open source software met zich meebrengt dat Opdrachtgever (delen van) de software alleen als open source kan verspreiden, zal Kaigo IT Solutions Opdrachtgever afdoende informeren over alle van toepassing zijnde licentievoorwaarden.</p>
            <p>6.3. Na oplevering ligt de verantwoordelijkheid op een correcte naleving van de betreffende licenties van derden bij het gebruik van de ontwikkelde Werken bij Opdrachtgever.</p>

            <h4 className="header">7. Rechten van intellectuele eigendom</h4>
            <p>7.1. Alle rechten van intellectuele eigendom op alle in het kader van de overeenkomst ontwikkelde of geleverde Diensten of Werken berusten uitsluitend bij Kaigo IT Solutions of diens licentiegevers. Uitsluitend indien expliciet in de offerte vermeld of apart expliciet overeengekomen kunnen rechten overgedragen worden aan Opdrachtgever.</p>
            <p>7.2. Opdrachtgever verkrijgt uitsluitend de gebruiksrechten en bevoegdheden die voortvloeien uit de strekking van de overeenkomst of die schriftelijk worden toegekend en voor het overige zal Opdrachtgever de Werken of andere resultaten van Diensten materialen niet verveelvoudigen of openbaar maken.</p>
            <p>7.3. Opdrachtgever is niet gerechtigd wijzigingen in Werken aan te brengen die zij in gebruiksrecht krijgt, tenzij dat noodzakelijk is voor het beoogde gebruik of om fouten te herstellen.</p>
            <p>7.4. Kaigo IT Solutions zal de bronbestanden (zoals beeld-, website- of softwarebroncode) van als maatwerk ontwikkelde Werken aan Opdrachtgever ter beschikking stellen na betaling van de betreffende factuur of facturen.</p>
            <p>7.5. Het is Opdrachtgever niet toegestaan enige aanduiding omtrent auteursrechten, merken, handelsnamen of andere rechten van intellectuele eigendom uit de materialen te verwijderen of te wijzigen uit Werken die zij in licentie krijgt, inclusief aanduidingen omtrent het vertrouwelijk karakter en geheimhouding van de materialen.</p>

            <h4 className="header">8. Prijzen en betaling</h4>
            <p>8.1. Voor alle werkzaamheden zal Kaigo IT Solutions maandelijks factureren op basis van de daadwerkelijk gemaakte uren. Voor werkzaamheden buiten kantoortijden kan Kaigo IT Solutions een toeslag bedingen van maximaal 200% van het normale tarief.</p>
            <p>8.2. Kaigo IT Solutions zal voor de door Opdrachtgever verschuldigde bedragen een elektronische factuur sturen aan Opdrachtgever.</p>
            <p>8.3. De betalingstermijn van facturen is veertien dagen na de datum van de factuur, tenzij een langere betalingstermijn is aangegeven op de factuur. Indien Opdrachtgever niet tijdig betaalt, is hij na het verstrijken van deze periode van rechtswege in verzuim zonder dat hiervoor ingebrekestelling is vereist. Indien een verschuldigd bedrag niet binnen de betalingstermijn wordt voldaan is over het openstaande factuurbedrag de wettelijke rente verschuldigd.</p>
            <p>8.4. Indien Opdrachtgever meent dat (een gedeelte van) een factuur onjuist is, dient hij dit binnen de betalingstermijn aan Kaigo IT Solutions te melden. De betalingsverplichting van het betwiste (maar niet het overige) wordt opgeschort totdat Kaigo IT Solutions de melding heeft onderzocht. Indien na onderzoek van Kaigo IT Solutions blijkt dat de betwisting onterecht was, dient Opdrachtgever binnen zeven dagen het betwiste alsnog te voldoen.</p>
            <p>8.5. Bij een niet tijdige betaling is Opdrachtgever, naast het verschuldigde bedrag en de daarop verschenen rente, gehouden tot een volledige vergoeding van zowel buitengerechtelijke als gerechtelijke incassokosten, daaronder begrepen de kosten voor advocaten, deurwaarders en incassobureaus. In het bijzonder is Kaigo IT Solutions in dit geval gerechtigd administratiekosten van € 50 in rekening te brengen.</p>
            <p>8.6. De vordering tot betaling is direct opeisbaar ingeval Opdrachtgever in staat van faillissement wordt verklaard, surséance van betaling aanvraagt dan wel algeheel beslag op vermogensbestanddelen van Opdrachtgever wordt gelegd, Opdrachtgever overlijdt, in liquidatie treedt of wordt ontbonden.</p>

            <h4 className="header">9. Geheimhouding</h4>
            <p>9.1. Partijen zullen informatie die zij voor, tijdens of na de uitvoering van de overeenkomst aan elkaar verstrekken, vertrouwelijk behandelen wanneer deze informatie is gemarkeerd als vertrouwelijk of wanneer de ontvangende partij weet of behoort te weten dat de informatie als vertrouwelijk bedoeld was. Partijen leggen deze verplichting tevens op aan hun werknemers alsmede aan door hen ingeschakelde derden ter uitvoering van de overeenkomst.</p>
            <p>9.2. Kaigo IT Solutions zal zich inspannen om te vermijden dat zij kennis neemt van gegevens die Opdrachtgever opslaat en/of verspreidt via de hardware of software waar de Diensten op betrekking hebben, tenzij dit noodzakelijk is voor een goede uitvoering van de overeenkomst of Kaigo IT Solutions daartoe verplicht is krachtens een wettelijke bepaling of gerechtelijk bevel. In dat geval zal Kaigo IT Solutions zich inspannen de kennisname van de gegevens zo veel mogelijk te beperken, voor zover dit binnen haar macht ligt.</p>
            <p>9.3. Kaigo IT Solutions mag de opgedane kennis bij het uitvoeren van de overeenkomst gebruiken voor andere opdrachten, voorzover hierbij geen informatie van Opdrachtgever in strijd met verplichtingen omtrent vertrouwelijkheid beschikbaar komt voor derden.</p>
            <p>9.4. De verplichtingen uit dit artikel blijven ook bestaan na beëindiging van de overeenkomst om welke reden dan ook, en wel voor zolang als de partij die de informatie verstrekt redelijkerwijs aanspraak kan maken op het vertrouwelijke karakter van de informatie.</p>

            <h4 className="header">10. Aansprakelijkheid</h4>
            <p>10.1. Kaigo IT Solutions is slechts aansprakelijk tegenover Opdrachtgever in het geval van een toerekenbare tekortkoming in de nakoming van de overeenkomst en uitsluitend voor vervangende schadevergoeding, dat wil zeggen vergoeding van de waarde van de achterwege gebleven prestatie.</p>
            <p>10.2. Iedere aansprakelijkheid van Kaigo IT Solutions voor enige andere vorm van schade is uitgesloten, waaronder onder meer begrepen is aanvullende schadevergoeding in welke vorm dan ook, vergoeding van indirecte schade of gevolgschade, schade wegens misgelopen omzet of winst, schade wegens verlies van gegevens alsook schade wegens overschrijding van termijnen als gevolg van gewijzigde omstandigheden.</p>
            <p>10.3. In geval van aansprakelijkheid krachtens het eerste lid zal het maximale bedrag dat Kaigo IT Solutions is gehouden te vergoeden, gelijk zijn aan het voor de betreffende Dienst verschuldigde bedrag. Dit maximumbedrag komt te vervallen indien en voor zover de schade het gevolg is van opzet of grove schuld van Kaigo IT Solutions.</p>
            <p>10.4. De aansprakelijkheid van Kaigo IT Solutions wegens toerekenbare tekortkoming in de nakoming van de overeenkomst ontstaat slechts indien Opdrachtgever Kaigo IT Solutions direct en deugdelijk schriftelijk in gebreke stelt, waarbij een redelijke termijn gesteld wordt om de tekortkoming weg te nemen, en Kaigo IT Solutions ook na die termijn toerekenbaar in de nakoming van zijn verplichtingen tekort blijft schieten. De ingebrekestelling dient een zo gedetailleerd mogelijke omschrijving van de tekortkoming te bevatten, zodat Kaigo IT Solutions in staat is adequaat te reageren.</p>
            <p>10.5. In geval van overmacht, waaronder in ieder geval wordt verstaan storingen of uitvallen van het internet, de telecommunicatie-infrastructuur, stroomstoringen, binnenlandse onlusten, mobilisatie, oorlog, stremming in het vervoer, staking, uitsluiting, bedrijfsstoornissen, stagnatie in toelevering, brand, overstroming, in- en uitvoerbelemmeringen en in het geval dat Kaigo IT Solutions door zijn eigen leveranciers, ongeacht de reden daartoe, niet tot levering in staat wordt gesteld waardoor nakoming van de overeenkomst redelijkerwijs niet van Kaigo IT Solutions kan worden gevergd, zal de uitvoering van de overeenkomst worden opgeschort, dan wel de overeenkomst worden beëindigd wanneer de overmachtsituatie langer dan negentig dagen heeft geduurd, alles zonder enige verplichting tot schadevergoeding.</p>

            <h4 className="header">11. Duur en opzegging</h4>
            <p>11.1. De overeenkomst wordt aangegaan voor de termijn nodig voor levering van de Diensten. De overeenkomst kan tussentijds slechts worden opgezegd zoals in deze algemene voorwaarden bepaald, of met goedvinden van beide partijen. Opdrachtgever kan de overeenkomst tussentijds opzeggen tegen betaling van een afkoopsom gelijk aan de gemaakte uren tegen binnen Kaigo IT Solutions geldend uurtarief.</p>
            <p>11.2. Na opzegging, beëindiging of ontbinding om welke reden dan ook is Kaigo IT Solutions gerechtigd om per direct na de datum waarop de overeenkomst afloopt alle ten behoeve van Opdrachtgever bij hemzelf opgeslagen gegevens te wissen. Kaigo IT Solutions is niet verplicht in dat geval Opdrachtgever een kopie van deze gegevens te verschaffen.</p>
            <p>11.3. De overeenkomst eindigt automatisch indien een partij in staat van faillissement wordt verklaard, surséance van betaling aanvraagt dan wel algeheel beslag op vermogensbestanddelen krijgt gelegd, overlijdt, in liquidatie treedt of wordt ontbonden.</p>

            <h4 className="header">12. Wijzigingen in overeenkomst</h4>
            <p>12.1. Na aanvaarding mag de overeenkomst slechts met wederzijdse toestemming worden gewijzigd.</p>
            <p>12.2. Indien de overeenkomst een duurovereenkomst is, is Kaigo IT Solutions echter eens per kalenderjaar gerechtigd deze algemene voorwaarden eenzijdig aan te passen of uit te breiden. Zij dient hiertoe minstens twee maanden voordat de aanpassingen of uitbreidingen effect zullen krijgen, mededeling te doen aan Opdrachtgever. Wijzigingen in de algemene voorwaarden kunnen een specifieke afspraak echter nimmer opzij zetten.</p>
            <p>12.3. Indien Opdrachtgever binnen deze periode bezwaar maakt, zal Kaigo IT Solutions overwegen of zij de bezwaarlijke aanpassingen of uitbreidingen in wenst te trekken of niet. Kaigo IT Solutions zal van deze beslissing mededeling doen aan Opdrachtgever. Indien Kaigo IT Solutions bezwaarlijke aanpassingen of uitbreidingen niet wenst in te trekken, heeft Opdrachtgever het recht de overeenkomst op te zeggen per de datum dat deze effect zullen krijgen.</p>
            <p>12.4. Kaigo IT Solutions mag op elk moment wijzigingen in deze algemene voorwaarden doorvoeren als deze noodzakelijk zijn vanwege gewijzigde wettelijke regelingen. Tegen dergelijke wijzigingen kan Opdrachtgever geen bezwaar maken.</p>
            <p>12.5. Bovengenoemde regeling is tevens van toepassing op prijzen.</p>

            <h4 className="header">13. Slotbepalingen</h4>
            <p>13.1. Op deze overeenkomst is Nederlands recht van toepassing. Voor zover door de regels van dwingend recht niet anders wordt voorgeschreven, zullen alle geschillen die mochten ontstaan naar aanleiding van deze overeenkomst worden voorgelegd aan de bevoegde Nederlandse rechter voor het arrondissement waarin Kaigo IT Solutions gevestigd is.</p>
            <p>13.2. Indien enige bepaling uit deze overeenkomst nietig blijkt te zijn, tast dit niet de geldigheid van de gehele overeenkomst aan. Partijen zullen in dat geval ter vervanging (een) nieuwe bepaling(en) vaststellen, waarmee zoveel als rechtens mogelijk is aan de bedoeling van de oorspronkelijke overeenkomst en deze algemene voorwaarden gestalte wordt gegeven.</p>
            <p>13.3. Onder "schriftelijk" valt in deze voorwaarden ook e-mail en communicatie per fax, mits de identiteit van de afzender en de integriteit van de inhoud voldoende vaststaat. Partijen zullen zich inspannen de ontvangst en inhoud van communicatie per e-mail te bevestigen.</p>
            <p>13.4. De door Kaigo IT Solutions ontvangen of opgeslagen versie van enige communicatie geldt als authentiek, behoudens tegenbewijs te leveren door Opdrachtgever.</p>
            <p>13.5. Iedere partij is slechts gerechtigd haar rechten en verplichtingen uit de overeenkomst over te dragen aan een derde met voorafgaande schriftelijke toestemming van de andere partij. In afwijking hiervan is Kaigo IT Solutions steeds gerechtigd haar rechten en verplichtingen uit de overeenkomst over te dragen aan een moeder-, dochter- of zustermaatschappij.</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Terms